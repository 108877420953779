'use strict';

const ReadMore = {};

ReadMore.init = () => {
    ReadMore.onShowMoreClick();
};

ReadMore.onShowMoreClick = () => {
    $('body').on('click', '.show-more span', function () {
        var $this = $(this);
        var $content = $this.parent().prev('div.content');
        var linkText = $this.text();
        if (linkText === $(this).data('more')) {
            linkText = $(this).data('less');
            $content.find('.dots').addClass('d-none');
            $content.find('.more').removeClass('d-none');
        } else {
            linkText = $(this).data('more');
            $content.find('.dots').removeClass('d-none');
            $content.find('.more').addClass('d-none');
            if ($content.length) {
                $(window).scrollTop($content.offset().top - 156);
            }
        }
        $this.text(linkText);
    });
};

module.exports = ReadMore;
