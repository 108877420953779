'use strict';

const isEqual = require('lodash/isEqual');

const kxtsegsUpdatedEventHandler = function () {
    var container = $('.js-segments');
    var sessionSegments = container.data('session');
    var sessionSegmentssArr = sessionSegments && sessionSegments.length > 0 ? sessionSegments.split(',') : [];

    var localSegments = localStorage.getItem('kxtorfslnv_segs');
    var localSegmentsArr = localSegments && localSegments.length > 0 ? localSegments.split(',') : [];

    if (!isEqual(localSegmentsArr.sort(), sessionSegmentssArr.sort())) {
        var url = container.data('url');
        $.get(url, { segments: localSegments });
    }
};

const init = () => {
    /*
    * Create a custom event handler when "localStorage.kxtorfslnv_segs" gets updated
    * by Stitchd <steven.thijs@stitchd.be>
    */
    let kxtsegsOriginalSetItem = localStorage.setItem;

    localStorage.setItem = function (key, value) {
        if (key === 'kxtorfslnv_segs' && value !== localStorage.kxtorfslnv_segs) {
            kxtsegsOriginalSetItem.apply(this, arguments);
            var event = new Event('kxtsegs_updatedEvent');
            document.dispatchEvent(event);
        } else {
            kxtsegsOriginalSetItem.apply(this, arguments);
        }
    };
    /* *** */

    // call event handler once in case "localStorage.kxtorfslnv_segs" has been updated before this is run.
    kxtsegsUpdatedEventHandler();
    // from now on, call event handler everytime "localStorage.kxtorfslnv_segs" is updated.
    document.addEventListener('kxtsegs_updatedEvent', kxtsegsUpdatedEventHandler, false);
};

module.exports = {
    init: init
};
