'use strict';

/**
 * Makes the correct link bold
 * @param {string} selector - the selector on which list to check the urls
 */
function checkCurrentUrl(selector) {
    const currenturl = window.location.href;
    $(selector).each((i, el) => {
        if (el.href === currenturl) {
            $(el).addClass('leftMenu__current-url');
            return false;
        }
        return true;
    });
}

module.exports = {
    init: function () {
        checkCurrentUrl('.leftMenu-mobile a');
        checkCurrentUrl('.leftMenu-desktop a');
    }
};
